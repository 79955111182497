import MainScreen from "../Components/Containers/DashBoard/MainScreen";
import OpportunityDetails from "../Components/Containers/Opportunity/OpportunityDetails";
import SalesGrid from "../Components/Containers/Sales/SalesGrid";
import SaleDetails from "../Components/Containers/Sales/SaleDetails";
import SaleCalendar from "../Components/Containers/Sales/SaleCalendar";
import PipelineStages from "../Components/Containers/Sales/PipelineStages";
import OpportunityGrid from "../Components/Containers/Opportunity/OpportunityGrid";
import ContactsGrid from "../Components/Containers/Contacts/ContactsGrid";
import CompanyGrid from "../Components/Containers/Companies/CompanyGrid";
import CompaniesMiniProfilesGrid from "../Components/Containers/CompaniesMiniProfiles/CompaniesMiniProfilesGrid";
import ProductsGrid from "../Components/Containers/Products/ProductsGrid";
import ContactDetails from "../Components/Containers/Contacts/ContactDetails";
import CompanyDetails from "../Components/Containers/Companies/CompanyDetails";
import ProductDetails from "../Components/Containers/Products/ProductDetails";
import Templates from "../Components/Containers/Templates/Templates";
import EventsOverview from "../Components/Containers/EventsOverview/EventsOverview";
import Calendar from "../Components/Containers/Calendar/Calendar";
import Capabilities from "../Components/Containers/Capabilities/Capabilities";
import UserDetails from "../Components/Containers/Users/UserDetails";
import UsersGrid from "../Components/Containers/Users/UsersGrid";
import NetworkDirector from "../Components/Containers/NetworkDirector/NetworkDirector";
import NetworkDirectorOverview from "../Components/Containers/NetworkDirector/NetworkDirectorOverview";
import CaseOverview from "../Components/Containers/CaseOverview/CaseOverview";
import ResaleOverview from "../Components/Containers/ResaleOverview/ResaleOverview";
import SegmentBuilder from "../Components/Containers/SegmentBuilder/SegmentBuilder";
import Teams from "../Components/Containers/Teams/Teams";
import GmailDataGrid from "../Components/Containers/MailExtractorOverview/GmailDataGrid";
import InvoiceGrid from '../Components/Containers/Invoices/InvoiceGrid';
import InvoiceDetails from '../Components/Containers/Invoices/InvoiceDetails';
import ReleaseNotes from '../Components/Containers/ReleaseNotes/ReleaseNotes';
import BouncedEmail from '../Components/Containers/BouncedEmail/BouncedEmail';
import ManageBouncedEmails from '../Components/Containers/BouncedEmail/ManageBouncedEmails';
import SystemSettings from "../Components/Containers/SystemSettings/SystemSettings";
import ChangePersonGroupRequestForm from "../Components/Containers/ChangePersonGroup/ChangePersonGroupRequestForm";
import ChangePersonGroupGrid from "../Components/Containers/ChangePersonGroup/ChangePersonGroupGrid";
import ChangePersonGroupDetails from "../Components/Containers/ChangePersonGroup/ChangePersonGroupDetails";
import TransferLaterGrid from "../Components/Containers/ChangePersonJob/TransferLaterGrid";
import TodoGrid from "../Components/Containers/ClientRetention/TodoGrid";
import AssignTodoToKpi from "../Components/Containers/ClientRetention/AssignTodoToKpi";
import BookkeeperGrid from "../Components/Containers/Bookkeepers/BookkeeperGrid";
import CompaniesMiniCompaniesGrid from "../Components/Containers/CompaniesMiniCompanies/CompaniesMiniCompaniesGrid";

export const adminRoutes = [
    {
        path: '/',
        component: <Calendar/>
    },
    {
        path: '/opportunities',
        component: <OpportunityGrid/>
    },
    {
        path: '/opportunities/id/:id',
        component: <OpportunityDetails/>
    },
    {
        path: '/opportunities/id/:id?tab=history',
        component: <OpportunityDetails/>
    },
    {
        path: '/sales',
        component: <SalesGrid/>
    },
    {
        path: '/sales/id/:id',
        component: <SaleDetails/>
    },
    {
        path: '/sales-view/calendar',
        component: <SaleCalendar/>
    },
    {
        path: '/sales/pipeline',
        component: <PipelineStages/>
    },
    {
        path: '/contacts',
        component: <ContactsGrid/>
    },
    {
        path: '/email-extraction-data',
        component: <GmailDataGrid/>,
    },
    {
        path: "/contact/id/:id",
        component: <ContactDetails/>,
    },
    {
        path: "/contact/id/:id/change-group",
        component: <ChangePersonGroupRequestForm/>,
    },
    {
        path: "/companies",
        component: <CompanyGrid/>,
    },
    {
        path: "/company/id/:id",
        component: <CompanyDetails/>,
    },
    {
        path: "/manage-companies/companies",
        component: <CompaniesMiniCompaniesGrid/>,
    },
    {
        path: "/manage-companies/profiles",
        component: <CompaniesMiniProfilesGrid/>,
    },
    {
        path: "/sales/calendar",
        component: <Calendar/>,
    },
    {
        path: "/products",
        component: <ProductsGrid/>,
    },
    {
        path: "/product/id/:id",
        component: <ProductDetails/>,
    },
    {
        path: "/admin/mail-templates/create",
        component: <Templates/>,
    },
    {
        path: "/admin/segment-builder",
        component: <SegmentBuilder/>,
        capabilities: ["segment_builder_restricted"],
    },
    {
        path: "/events/overview",
        component: <EventsOverview/>,
        capabilities: ["event_overview"],
    },
    {
        path: "/case/overview",
        component: <CaseOverview/>,
        capabilities: ["event_overview", "case_overview"],
    },
    {
        path: "/resale/overview",
        component: <ResaleOverview/>,
        capabilities: ["event_overview", "resale_overview"],
    },
    {
        path: "/admin/capabilities",
        component: <Capabilities/>,
    },
    {
        path: "/admin/user/add",
        component: <NetworkDirector/>,
    },
    {
        path: "/admin/users",
        component: <UsersGrid/>,
    },
    {
        path: "/user/id/:id",
        component: <UserDetails/>,
    },
    {
        path: "/admin/teams",
        component: <Teams/>,
    },
    {
        path: "admin/release-notes",
        component: <ReleaseNotes/>,
    },
    {
        path: "/change-member-group",
        component: <ChangePersonGroupGrid/>,
    },
    {
        path: '/finance',
        component: <InvoiceGrid/>
    },
    {
        path: '/finance/id/:id',
        component: <InvoiceDetails/>
    },
    {
        path: "/change-member-group/id/:id",
        component: <ChangePersonGroupDetails/>,
    },
    {
        path: '/Bounced-Emails',
        component: <BouncedEmail/>
    },
    {
        path: '/bounced-emails/attachments',
        component: <ManageBouncedEmails/>
    },
    {
        path: 'site-settings',
        component: <SystemSettings/>
    },
    {
        path: '/Bounced-Emails',
        component: <BouncedEmail/>
    },
    {
        path: '/bounced-emails/attachments',
        component: <ManageBouncedEmails/>
    },
    {
        path: "/change-member-group",
        component: <ChangePersonGroupGrid/>,
    },
    {
        path: "/change-member-group/id/:id",
        component: <ChangePersonGroupDetails/>,
    },
    {
        path: "/transfer-membership",
        component: <TransferLaterGrid/>,
    },
    {
        path: "/todos",
        component: <TodoGrid/>
    },
    {
        path: "/assign-todos-to-kpis",
        component: <AssignTodoToKpi/>
    },
    {
        path: "/bookkeepers",
        component: <BookkeeperGrid/>,
    },
]

export const salesRepRoutes = [
    {
        path: '/',
        component: <MainScreen/>
    },
    {
        path: '/opportunities/id/:id?tab=history',
        component: <OpportunityDetails/>
    },
    {
        path: '/opportunities',
        component: <OpportunityGrid/>
    },
    {
        path: '/opportunities/id/:id',
        component: <OpportunityDetails/>
    },
    {
        path: '/sales-view/calendar',
        component: <SaleCalendar/>
    },
    {
        path: '/sales/pipeline',
        component: <PipelineStages/>
    },
    {
        path: '/admin/mail-templates/create',
        component: <Templates/>
    },
    {
        path: '/finance',
        component: <InvoiceGrid/>
    },
    {
        path: '/finance/id/:id',
        component: <InvoiceDetails/>
    },
    {
        path: "/todos",
        component: <TodoGrid/>
    }
];

export const networkDirectorRoutes = [
    {
        path: '/',
        component: <NetworkDirectorOverview/>
    }
]
