//Statuses Constants
export const PENDING = "Pending";
export const CURRENT = 'Current';
export const FUTURE = 'Future';
export const PROCESSED = "Processed";
//Invoices constants
export const INVOICE_TYPE_INVOICE = "Invoice";
export const INVOICE_TYPE_ORDER = "Order";
export const INVOICE_STATUS_PENDING = "Pending";
export const INVOICE_STATUS_DRAFT = "Draft";
export const INVOICE_STATUS_SENT = "Sent";
export const INVOICE_STATUS_ARCHIVED_OR_INVOICED = "Archived/Invoiced";
export const PAYMENT_TERM_BETALES_INDEN = "Betales inden";
export const DK_STANDARD_LAYOUT = "DK standard template";
export const PERSON_NAME_MERGE_TAG = "[person_name]";
export const TRANSFER_MEMBERSHIP = "Transfer Membership";
export const DUPLICATE_MEMBERSHIP = "Duplicate Membership";
export const TRANSFER_NOW = "Transfer Now";
export const TRANSFER_LATER_CANCEL_NOW = "Transfer Later & Cancel Now"
export const TRANSFER_LATER = "Transfer Later"
export const RESALE = "resale";
export const NEW_SALE = "new_sale";
export const WIN_BACK = "win_back";

//E-conomic constants
export const ECONOMIC_BASE_URL = "https://restapi.e-conomic.com";
export const COUNTRY_DENMARK = "Denmark";

//Module names
export const MODULE_FINANCE = "Finance";
export const MODULE_CHANGE_MEMBER_GROUP = "Change Member Group";
export const MODULE_TRANSFER_MEMBERSHIP = "Transfer Membership";
export const MODULE_CLIENT_RETENTION = "Client Retention";
//Person Group Change
export const FIRST_MEETING_IN_NEXT_GROUP_COOL_OFF_DAYS = 30;
export const PRODUCT_TYPE_NETWORK_GROUP = [1, 12];
export const MULTIPLE_CHOICE = "Multiple Choice";
export const SINGLE_CHOICE = "Single Choice";
export const CLOSED_LOST = "closed:lost";
export const WIN_BACK_OPPORTUNITY_REASON = "win_back_opportunity_reason";

export function getEconomicDocumentDraftUrl(draftNumber, documentType) {
    if (documentType === INVOICE_TYPE_ORDER) {
        return `${ECONOMIC_BASE_URL}/orders/drafts/${draftNumber}/pdf`;
    } else {
        return `${ECONOMIC_BASE_URL}/invoices/drafts/${draftNumber}/pdf`;
    }
}

export function getEconomicDocumentSentUrl(invoiceNumber, documentType) {
    if (documentType === INVOICE_TYPE_ORDER) {
        return `${ECONOMIC_BASE_URL}/orders/sent/${invoiceNumber}/pdf`;
    } else {
        return `${ECONOMIC_BASE_URL}/invoices/booked/${invoiceNumber}/pdf`;
    }
}



