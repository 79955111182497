import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {
    Card,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Typography
} from "@mui/material";
import {ArrowCircleUp, Close} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import Button from "@mui/material/Button";
import {LoadingButton} from "@mui/lab";
import {connect} from "react-redux";
import {actions as opportunityActions, selectors as opportunitySelectors} from "../../../Ducks/opportunity";
import {bindActionCreators} from "redux";
import {parseCVR} from "../../Utils/validations";
import {createBookkeeper, updateBookkeeper} from "../../../API/bookkeeper";
import {toast} from "react-toastify";

const ManageBookkeeperModal = (props) => {
    const {open, data} = props;
    const formRef = useRef(null);
    const [bookkeeper, setBookkeeper] = useState(null);
    const [suggestedCompanyName, setSuggestedCompanyName] = useState('');

    useEffect(() => {
        if (open) {
            setSuggestedCompanyName('');
        }

        setBookkeeper(data);
    }, [data, open]);

    useEffect(() => {
        formRef.current?.setFieldValue("cvr", bookkeeper?.cvr);
        formRef.current?.setFieldValue("company_name", bookkeeper?.company_name);
        formRef.current?.setFieldValue("ean", bookkeeper?.ean);
        formRef.current?.setFieldValue("bookkeeper_email", bookkeeper?.bookkeeper_email);
        formRef.current?.setFieldValue("po_number", bookkeeper?.po_number);
    }, [bookkeeper, formRef]);

    useEffect(() => {
        if (props.cvr) {
            let parsedCvr = parseCVR(props.cvr);

            if (parsedCvr !== false) {
                if (data) {
                    setSuggestedCompanyName(parsedCvr.company_name);
                } else {
                    formRef?.current?.setFieldValue('company_name', parsedCvr.company_name);
                }
            } else {
                setSuggestedCompanyName('');
            }
        }
    }, [props.cvr, props.searchCvrLoading]);

    const handleClose = () => {
        setBookkeeper(null);
        setSuggestedCompanyName('');

        props.handleClose(false);
    }

    const handleCVR = (e) => {
        const {value} = e.target;

        if (value !== "" && !value.includes("-")) {
            props.searchCvr(value);
        }
    }

    return (
        <Dialog key={open ? 'open' : 'closed'} open={open} scroll="paper" fullWidth maxWidth={'md'}
                sx={{'& .MuiDialog-paper': {maxHeight: '80vh'}}}>
            <DialogTitle
                sx={{backgroundColor: '#1565c0', color: 'white'}}>Manage Bookkeeper</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500, 100],
                })}
            >
                <Close/>
            </IconButton>
            <DialogContent sx={{padding: '14px', backgroundColor: '#F7F7F7'}}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Card sx={{padding: '14px', marginRight: '7px', backgroundColor: 'white'}}>
                            <Typography
                                variant="span"
                                component="span"
                                sx={{fontWeight: 'bold', marginBottom: '10px'}}
                            >
                                {bookkeeper ? 'Edit' : 'Add'} Bookkeeper
                            </Typography>
                            <Formik
                                innerRef={formRef}
                                initialValues={{
                                    cvr: '',
                                    company_name: '',
                                    ean: '',
                                    bookkeeper_email: '',
                                    po_number: '',
                                    enableReinitialize: true
                                }}
                                validate={(values) => {
                                    const errors = {};

                                    if (!values.cvr) {
                                        errors.cvr = 'Required';
                                    }

                                    if (!values.company_name) {
                                        errors.company_name = 'Required';
                                    }

                                    if (!values.ean && !values.bookkeeper_email) {
                                        errors.ean = 'Required';
                                        errors.bookkeeper_email = 'Required';
                                    }

                                    if (values.bookkeeper_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.bookkeeper_email)) {
                                        errors.bookkeeper_email = 'Invalid email address';
                                    }

                                    if (values.ean && values.ean.length !== 13) {
                                        errors.ean = 'EAN should be 13 characters';
                                    }

                                    if (/[!@$^%+=#&*():;.<>?,"']/i.test(values.ean)) {
                                        errors.ean = 'Invalid EAN';
                                    }

                                    return errors;
                                }}
                                onSubmit={(values, {setSubmitting}) => {

                                    if (bookkeeper) {
                                        updateBookkeeper(bookkeeper.bookkeeper_id, values).then((res) => {

                                            if (res?.message) {
                                                toast.success(res.message);
                                                props.handleClose(true);
                                            }

                                            setSubmitting(false);
                                        }).catch((error) => {
                                            toast.error(error.message);
                                            setSubmitting(false);
                                        })
                                    } else {
                                        createBookkeeper(values).then((res) => {
                                            if (res?.message) {
                                                toast.success(res.message);
                                                setSuggestedCompanyName('');
                                                props.handleClose(true);
                                            }

                                            setSubmitting(false);
                                        }).catch((error) => {
                                            toast.error(error.message);
                                            setSubmitting(false);
                                        })
                                    }
                                }}>
                                {({values, isSubmitting, setFieldValue}) => (
                                    <Form>
                                        <Grid container spacing={2} sx={{marginTop: '10px'}}>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography
                                                    variant='span'
                                                    component='span'
                                                >
                                                    CVR*
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} style={{margin: 'auto'}}>
                                                <Field
                                                    name='cvr'
                                                    type='text'
                                                    label='CVR*'
                                                    variant='outlined'
                                                    margin='normal'
                                                    fullWidth={true}
                                                    component={TextField}
                                                    size='small'
                                                    onBlur={handleCVR}
                                                    InputProps={{
                                                        endAdornment: (
                                                            props.searchCvrLoading ? (
                                                                <InputAdornment position="end">
                                                                    <CircularProgress size={20}/>
                                                                </InputAdornment>
                                                            ) : null
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{marginTop: '10px'}}>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography
                                                    variant='span'
                                                    component='span'
                                                >
                                                    Company Name*
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} style={{margin: 'auto'}}>
                                                <Field
                                                    name='company_name'
                                                    type='text'
                                                    label='Company Name*'
                                                    variant='outlined'
                                                    margin='normal'
                                                    fullWidth={true}
                                                    component={TextField}
                                                    size='small'
                                                    style={{marginBottom: '0px'}}
                                                />
                                                <div style={{
                                                    fontSize: "12px",
                                                    color: "gray",
                                                    padding: "0px"
                                                }}>
                                                    <><span>{suggestedCompanyName}</span>
                                                        {
                                                            suggestedCompanyName &&
                                                            <IconButton sx={{fontSize: "10px"}}
                                                                        onClick={() => {
                                                                            setFieldValue('company_name', suggestedCompanyName);
                                                                        }}><ArrowCircleUp color='primary'/></IconButton>
                                                        }
                                                    </>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{marginTop: '10px'}}>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography
                                                    variant='span'
                                                    component='span'
                                                >
                                                    EAN*
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} style={{margin: 'auto'}}>
                                                <Field
                                                    name='ean'
                                                    type='text'
                                                    label='EAN*'
                                                    variant='outlined'
                                                    margin='normal'
                                                    fullWidth={true}
                                                    component={TextField}
                                                    size='small'
                                                    onBlur={() => {
                                                        let _ean = values.ean?.trim();
                                                        _ean = _ean?.replace(/^0+/, "");

                                                        setFieldValue('ean', _ean);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{marginTop: '10px'}}>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography
                                                    variant='span'
                                                    component='span'
                                                >
                                                    Bookkeeper Email*
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} style={{margin: 'auto'}}>
                                                <Field
                                                    name='bookkeeper_email'
                                                    type='text'
                                                    label='Bookkeeper Email*'
                                                    variant='outlined'
                                                    margin='normal'
                                                    fullWidth={true}
                                                    component={TextField}
                                                    size='small'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{marginTop: '10px'}}>
                                            <Grid item xs={3} style={{margin: 'auto'}}>
                                                <Typography
                                                    variant='span'
                                                    component='span'
                                                >
                                                    PO Number
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={9} style={{margin: 'auto'}}>
                                                <Field
                                                    name='po_number'
                                                    label='PO Number'
                                                    variant='outlined'
                                                    margin='normal'
                                                    fullWidth={true}
                                                    component={TextField}
                                                    size='small'
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container sx={{marginBottom: "15px"}} spacing={1}>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "right",
                                                }}
                                            >
                                                <Button
                                                    variant='contained'
                                                    color='error'
                                                    onClick={() => {
                                                        handleClose();
                                                    }}
                                                    style={{
                                                        margin: "10px",
                                                        width: "85px"
                                                    }}
                                                    disabled={isSubmitting}
                                                >
                                                    Cancel
                                                </Button>
                                                <LoadingButton
                                                    variant='contained'
                                                    color='primary'
                                                    type='submit'
                                                    style={{
                                                        margin: "10px",
                                                        width: "85px"
                                                    }}
                                                    loading={isSubmitting}
                                                >
                                                    {bookkeeper ? 'Update' : 'Save'}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    cvr: opportunitySelectors.cvr(state),
    searchCvrLoading: opportunitySelectors.searchCvrLoading(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        searchCvr: opportunityActions.searchCvr
    },
    dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageBookkeeperModal);