import * as React from 'react';
import {Field, Form, Formik} from 'formik';
import {
    Autocomplete,
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    TextField as MaterialTextField,
    Typography
} from '@mui/material';
import {TextField} from 'formik-material-ui';
import {withStyles} from '@mui/styles';
import {actions as opportunityActions, selectors as opportunitySelectors} from '../../../../Ducks/opportunity';
import {selectors as dashboardSelectors} from '../../../../Ducks/dashboard';
import {actions as contactsActions, selectors as contactsSelectors} from '../../../../Ducks/contacts';
import {selectors as accountSelectors} from '../../../../Ducks/account';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import LostModalV2 from '../Modals/LostModalV2';
import WonModal from '../Modals/WonModal';
import CvrModal from '../Modals/CvrModal';
import TransferModal from '../Modals/TransferModal';
import {useParams} from 'react-router-dom';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectBox from '../../../Common/SelectBox';
import Swal from 'sweetalert2';
import UnsuccessfulModal from '../Modals/UnsuccessfulModal';
import SwitchToInvoicesConfirmationModal from '../Modals/SwitchToInvoicesConfirmationModal';
import {LoadingButton} from '@mui/lab';
import {fetchGet} from "../../../../API/util";
import Tooltip from "@mui/material/Tooltip";
import {Info, Save} from "@mui/icons-material";
import {getProductCountry} from "../../../../API/products";
import {COUNTRY_DENMARK} from "../../../Utils/constants";
import {selectors as adminSelectors} from "../../../../Ducks/admin";
import AlertElement from "../../../Common/Alert";
import {checkIfPersonFoodConsiderationHasChanged, parseCVR} from "../../../Utils/validations";

toast.configure();

const useStyles = _theme => ({
    noPadding: {
        padding: 0
    },
    container: {
        padding: 0,
        margin: 0
    }
});

class Deal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openLost: false,
            openWon: false,
            openCvr: false,
            openTransfer: false,
            openUnsuccessful: false,
            openSwitchToInvoicesConfirmation: false,
            selectedValue: null,
            formValues: {},
            cvrData: null,
            salesRep: "",
            users: [],
            pipelineID: "",
            pipelineStages: [],
            assignedOpportunity: false,
            errorMsg: [],
            productCountry: null,
            companyName: "",
            showCompanySave: false,
            showBookkeepingSave: false,
            showExtraInformationSave: false,
            opportunityPatchData: {},
            delayInvoiceOptions: [
                {
                    label: '0 Days',
                    key: '0 Days',
                    id: '0 Days'
                },
                {
                    label: '14 Days',
                    key: '14 Days',
                    id: '14 Days'
                },
                {
                    label: '30 Days',
                    key: '30 Days',
                    id: '30 Days'
                },
                {
                    label: '60 Days',
                    key: '60 Days',
                    id: '60 Days'
                },

            ]
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {

        const {
            opportunity,
            getFoodConsiderations,
            getSalesReps,
            foodConsiderations,
        } = this.props;

        getSalesReps();
        getFoodConsiderations();

        if (opportunity) {
            this.populateForm(opportunity, foodConsiderations);

            if (opportunity.main.product) {
                getProductCountry(opportunity.main.product).then((res) => {
                    if (res.country) {
                        this.setState({'productCountry': res.country});
                    }
                });
            }
        }
    }

    componentDidUpdate(prevProps) {

        const {cvr, salesReps, pipelineId, opportunity, closedWon, foodConsiderations} = this.props;

        if (prevProps.cvr !== cvr) {
            let parsedCvr = parseCVR(cvr);

            if (parsedCvr !== false) {
                this.setState({
                    cvrData: parsedCvr
                }, () => {
                    this.handleModal('openCvr')
                })
            } else {
                Swal.fire({
                    title: "Not Found",
                    text: "No matching record found against the entered CVR",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Ok",
                }).then(_result => {
                });
            }
        }

        if (prevProps.salesReps !== salesReps) {
            let users = salesReps.main.filter(item => item.user_deactivated === null && !item.user_lastname.includes("WRONG")).map((item, index) => {
                item.label = item.user_firstname + " " + item.user_lastname;
                item.key = {index}
                return item;
            });
            this.setState({users});
        }

        if (prevProps.pipelineId !== pipelineId && (this.state.salesRep !== "")) {
            if (pipelineId.length) {
                let pipeline_id = pipelineId[0].pipeline_id;
                this.setState({pipelineID: pipeline_id}, () => {
                    this.handleModal('openTransfer');
                })
            } else {
                // show toast
                toast.error('Cannot show stages as it has no pipeline id', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 2000
                });
            }
        }

        if (prevProps.opportunity !== opportunity) {
            opportunity && this.populateForm(opportunity, foodConsiderations);
        }

        // if (prevProps.opportunity.main.opportunity_id !== opportunity.main.opportunity_id) {
        //     console.log(foodConsiderations)
        //     opportunity && this.populateForm(opportunity, foodConsiderations);
        // }

        if (prevProps.closedWon !== closedWon) {
            this.setState({openSwitchToInvoicesConfirmation: true});
        }
    }

    checkOpportunityAssignment = (opportunity, pipelines) => {
        if (pipelines !== null) {
            const pipeline_stage_id = opportunity.main.pipeline_stage_id;
            const pipelines_arr = pipelines.main.pipeline_stages;
            if (pipelines_arr.includes(pipeline_stage_id)) {
                this.setState({assignedOpportunity: true});
            } else {
                this.setState({assignedOpportunity: false});
            }
        }
    }

    populateForm = (opportunity, foodConsiderations) => {
        let companyName = opportunity?.main?.opportunity_cvr_company ?? opportunity.related_objects.persons[opportunity.related_objects.jobs[opportunity.main.job_id].person_id].company_name;
        this.formRef.current.setFieldValue('opportunity_cvr', opportunity.main.opportunity_cvr);
        this.formRef.current.setFieldValue('company_name', companyName);
        this.formRef.current.setFieldValue('opportunity_address_1', opportunity.main.opportunity_address_1);
        this.formRef.current.setFieldValue('opportunity_address_2', opportunity.main.opportunity_address_2);
        this.formRef.current.setFieldValue('opportunity_address_postal_code', opportunity.main.opportunity_address_postal_code);
        this.formRef.current.setFieldValue('opportunity_address_city', opportunity.main.opportunity_address_city);
        this.formRef.current.setFieldValue('opportunity_address_country', opportunity.main.opportunity_address_country);
        this.formRef.current.setFieldValue('opportunity_bookkeeper_email', opportunity.main.opportunity_bookkeeper_email);
        this.formRef.current.setFieldValue('opportunity_ean', opportunity.main.opportunity_ean);
        this.formRef.current.setFieldValue('opportunity_po_number', opportunity.main.opportunity_po_number);
        this.formRef.current.setFieldValue('opportunity_delayed_invoice', opportunity.main.opportunity_delayed_invoice);
        this.formRef.current.setFieldValue('opportunity_price', opportunity.main.opportunity_price);
        this.formRef.current.setFieldValue('opportunity_split_payment', opportunity.main.opportunity_split_payment);
        this.formRef.current.setFieldValue('opportunity_food_considerations', opportunity.main.opportunity_food_considerations);
        this.formRef.current.setFieldValue('person_food_considerations_1', opportunity.main.opportunity_food_considerations_1 == "" ? foodConsiderations.type1_food.find(item => item.id == 17).id : opportunity.main.opportunity_food_considerations_1);

        this.formRef.current.setFieldValue('person_food_considerations_2', opportunity.main.opportunity_food_considerations_2?.length == 0 ? [foodConsiderations?.type2_food.find(item => item.id == 18)?.id] : opportunity.main.opportunity_food_considerations_2);
        this.formRef.current.setFieldValue('person_food_considerations_other', opportunity.main.opportunity_food_considerations_other);
        this.formRef.current.setFieldValue('opportunity_special_conditions', opportunity.main.opportunity_special_conditions);

        this.setState({"companyName": companyName});
    }

    handleModal = (modal) => {
        this.setState({[modal]: true});
    };

    handleClose = (result = null) => {
        this.setState({
            openLost: false,
            openWon: false,
            openCvr: false,
            openTransfer: false,
            openUnsuccessful: false,
            openSwitchToInvoicesConfirmation: false
        });

        let formData = {...this.state.opportunityPatchData};
        Object.keys(result).forEach((key) => {
            formData[key] = result[key];
        });

        this.setState({"opportunityPatchData": formData});
    };

    handleBlur = (e, component = "company") => {
        const {opportunity} = this.props;
        const {name, value} = e.target;

        const trimmedValue = value?.trim();
        let formData = {...this.state.opportunityPatchData};

        if (opportunity.main[name] !== trimmedValue) {
            formData [name] = trimmedValue;
            this.setState({"opportunityPatchData": formData});
        } else {
            Object.keys(this.state.opportunityPatchData).forEach((key) => {
                if (key === name) {
                    delete formData[name];

                    this.setState({"opportunityPatchData": formData});
                }
            })
        }

        switch (component) {
            case "company":
                this.displayCompanySave();
                break;
            case "bookkeeping":
                this.displayBookkeepingSave();
                break;
            case "extraInformation":
                this.displayExtraInformationSave();
                break;

        }
    }

    handleAllergiesChange = (e) => {
        let allergies = e.target.value;
        let filteredAllergies = allergies;

        if (allergies.includes(18)) {
            filteredAllergies = allergies.filter(item => item !== 18);
        } else if (!e.target.value.length) {
            filteredAllergies = [18];
        }

        this.formRef.current.setFieldValue('person_food_considerations_2', filteredAllergies);

        let formData = {...this.state.opportunityPatchData};
        formData.person_food_considerations_2 = filteredAllergies;

        this.setState({"opportunityPatchData": formData});
        this.displayExtraInformationSave();
    }

    handleCVR = (e) => {
        const {opportunity, searchCvr} = this.props;
        let formData = {...this.state.opportunityPatchData};

        if (opportunity.main[e.target.name] !== e.target.value) {
            formData.opportunity_cvr = e.target.value;

            this.setState({"opportunityPatchData": formData});

            if (e.target.value !== "" && !e.target.value.includes("-")) {
                searchCvr(e.target.value);
                //fetch bookkeeper by cvr
                this.fetchBookkeeperByCvr(e.target.value);
            }
    
        } else {
            Object.keys(this.state.opportunityPatchData).forEach((key) => {
                if (key === "opportunity_cvr") {
                    delete formData["opportunity_cvr"];

                    this.setState({"opportunityPatchData": formData});
                }
            })
        }

        this.displayCompanySave();
    }


    handleTransferOpportunity = (_event, value) => {
        const {getPipelineId} = this.props;

        if (value !== null) {
            getPipelineId(value.user_id);
            this.setState({salesRep: value.user_id});
        } else {
            this.setState({salesRep: ""});
        }
    }

    fetchBookkeeperByCvr = (cvrNumber) => {
        const api = `/bookkeeper/${cvrNumber}`;

        fetchGet(api).then((response) => {
            if (response) {
                let formData = {...this.state.opportunityPatchData};

                if (response.ean) {
                    this.formRef.current.setFieldValue('opportunity_ean', response.ean);
                    this.formRef.current.setFieldValue('opportunity_bookkeeper_email', '');

                    formData.opportunity_ean = response.ean;
                    formData.opportunity_bookkeeper_email = '';
                } else if (response.bookkeeper_email) {
                    this.formRef.current.setFieldValue('opportunity_ean', '');
                    this.formRef.current.setFieldValue('opportunity_bookkeeper_email', response.bookkeeper_email);

                    formData.opportunity_ean = '';
                    formData.opportunity_bookkeeper_email = response.bookkeeper_email;
                }

                if (response.po_number) {
                    this.formRef.current.setFieldValue('opportunity_po_number', response.po_number);
                    formData.opportunity_po_number = response.po_number;
                }

                this.setState({"opportunityPatchData": formData});
            }
        })
    }

    displayCompanySave = () => {
        const {opportunity} = this.props;
        const formValues = this.formRef.current.values;

        if (opportunity.main.opportunity_cvr !== formValues.opportunity_cvr) {
            this.setState({showCompanySave: true});
        } else if (this.state.companyName !== formValues.company_name) {
            this.setState({showCompanySave: true});
        } else if (opportunity.main.opportunity_address_1 !== formValues.opportunity_address_1) {
            this.setState({showCompanySave: true});
        } else if (opportunity.main.opportunity_address_2 !== formValues.opportunity_address_2) {
            this.setState({showCompanySave: true});
        } else if (opportunity.main.opportunity_address_postal_code !== formValues.opportunity_address_postal_code) {
            this.setState({showCompanySave: true});
        } else if (opportunity.main.opportunity_address_city !== formValues.opportunity_address_city) {
            this.setState({showCompanySave: true});
        } else if (opportunity.main.opportunity_address_country !== formValues.opportunity_address_country) {
            this.setState({showCompanySave: true});
        } else {
            this.setState({showCompanySave: false});
        }
    }

    displayBookkeepingSave = () => {
        const {opportunity} = this.props;
        const formValues = this.formRef.current.values;

        if (opportunity.main.opportunity_bookkeeper_email !== formValues.opportunity_bookkeeper_email) {
            this.setState({showBookkeepingSave: true});
        } else if (opportunity.main.opportunity_ean !== formValues.opportunity_ean) {
            this.setState({showBookkeepingSave: true});
        } else if (opportunity.main.opportunity_po_number !== formValues.opportunity_po_number) {
            this.setState({showBookkeepingSave: true});
        } else if (opportunity.main.opportunity_delayed_invoice !== formValues.opportunity_delayed_invoice) {
            this.setState({showBookkeepingSave: true});
        } else if (opportunity.main.opportunity_price !== formValues.opportunity_price) {
            this.setState({showBookkeepingSave: true});
        } else if (opportunity.main.opportunity_split_payment !== formValues.opportunity_split_payment) {
            this.setState({showBookkeepingSave: true});
        } else {
            this.setState({showBookkeepingSave: false});
        }
    }

    displayExtraInformationSave = () => {
        const {opportunity} = this.props;
        const formValues = this.formRef.current.values;

        if (opportunity.main.person_food_considerations_1 !== formValues.person_food_considerations_1) {
            this.setState({showExtraInformationSave: true});
        } else if (opportunity.main.person_food_considerations_2 !== formValues.person_food_considerations_2) {
            this.setState({showExtraInformationSave: true});
        } else if (opportunity.main.person_food_considerations_other !== formValues.person_food_considerations_other) {
            this.setState({showExtraInformationSave: true});
        } else {
            this.setState({showExtraInformationSave: false});
        }
    }

    isOpportunityStatusOpen = () => {
        const {opportunity} = this.props;
        const {self_opportunity} = opportunity?.main;
        return self_opportunity && opportunity?.main?.opportunity_status === "open";
    }

    saveOpportunity = () => {
        const {patchOpportunityData, opportunity, foodConsiderations} = this.props;
        const {opportunityPatchData} = this.state;
        let hasErrors = false;

        this.formRef.current.validateForm().then((errors) => {
            if (Object.keys(errors).length > 0) {
                this.formRef.current.setErrors(errors);

                Object.keys(errors).forEach((key) => {
                    this.formRef.current.setFieldTouched(key);
                });

                hasErrors = true;
            }

            if (opportunityPatchData.person_food_considerations_1 === "" || opportunityPatchData.person_food_considerations_2?.length === 0) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Food consideration can not be empty",
                });
                hasErrors = true;
            }

            if (!hasErrors) {
                const personFoodConsiderationHasChanged =
                    checkIfPersonFoodConsiderationHasChanged(opportunity.main, this.formRef, foodConsiderations, 'opportunity', this.props);

                if (!personFoodConsiderationHasChanged) {
                    patchOpportunityData({formData: opportunityPatchData, id: this.props.params.id});
                }
            }
        });
    }

    render() {
        const {
            classes,
            opportunity,
            user,
            foodConsiderations,
            postClosedWon_loading,
            financeEnabled,
            patchOpportunityDataLoading
        } = this.props;
        const {users, delayInvoiceOptions, productCountry} = this.state;
        const {user_details} = user;
        const capability = user_details.capabilities.filter((item) => item.capability_title === "can_close_lost_with_unsuccessful_campaign");

        return (
            <React.Fragment>
                <Backdrop sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1}}
                          open={patchOpportunityDataLoading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <div className="deals-container">
                    {
                        opportunity?.main.person_food_considerations_set_by_notice !== '' &&
                        <p>
                            <AlertElement severity="info"
                                          message={opportunity?.main.person_food_considerations_set_by_notice}/>
                        </p>
                    }
                    <Grid container sx={{paddingRight: "0px", marginRight: "0px"}}>
                        <Grid item xs={11}>
                            <Typography component="h1" variant="h6" align="left" color="textPrimary"
                                        gutterBottom>
                                {opportunity ? "Company info for invoice" : ""}
                            </Typography>
                        </Grid>
                        {this.state.showCompanySave &&
                            <Grid item xs={1} sx={{textAlign: "right", paddingLeft: "2px"}}>
                                <Tooltip title='Save' arrow placement='top'>
                                    <IconButton
                                        aria-label='Save'
                                        aria-haspopup='false'
                                        onClick={this.saveOpportunity}
                                        style={{
                                            color: "#000",
                                            fontSize: "17px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        <Save color='primary'/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                    <Card style={{padding: '12px'}}>
                        {
                            opportunity ? (
                                <Formik
                                    innerRef={this.formRef}
                                    initialValues={{
                                        opportunity_cvr: "",
                                        company_name: "",
                                        opportunity_address_1: "",
                                        opportunity_address_2: "",
                                        opportunity_address_postal_code: "",
                                        opportunity_address_city: "",
                                        opportunity_address_country: "",
                                        opportunity_bookkeeper_email: "",
                                        opportunity_ean: "",
                                        opportunity_po_number: "",
                                        opportunity_delayed_invoice: "",
                                        opportunity_price: "",
                                        opportunity_split_payment: "",
                                        opportunity_food_considerations: "",
                                        person_food_considerations_1: "",
                                        person_food_considerations_2: [],
                                        person_food_considerations_other: "",
                                        opportunity_special_conditions: "",
                                    }}
                                    validate={values => {
                                        const errors = {};

                                        if (!values.opportunity_ean && !values.opportunity_bookkeeper_email) {
                                            errors.opportunity_ean = 'Required';
                                            errors.opportunity_bookkeeper_email = 'Required';
                                        }

                                        const opportunityBookkeeperEmail = values.opportunity_bookkeeper_email?.trim();

                                        if (opportunityBookkeeperEmail && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(opportunityBookkeeperEmail)) {
                                            errors.opportunity_bookkeeper_email = 'Invalid email address';
                                        }

                                        if (values.opportunity_ean && values.opportunity_ean.length !== 13) {
                                            errors.opportunity_ean = 'EAN should be 13 characters';
                                        }

                                        if (/[!@$^%+=#&*():;.<>?,"']/i.test(values.opportunity_ean)) {
                                            errors.opportunity_ean = 'Invalid EAN';
                                        }

                                        if (!values.opportunity_cvr) {
                                            errors.opportunity_cvr = 'Required';
                                        }

                                        if (!values.company_name) {
                                            errors.company_name = 'Required';
                                        }

                                        return errors;
                                    }}
                                    onSubmit={(values, {setSubmitting}) => {
                                        this.setState({errorMsg: []});

                                        const {opportunity} = this.props;
                                        const selectedEvents = opportunity.related_objects.events_to_opportunities || {};
                                        const productTypes = opportunity.related_objects.product_types;
                                        const count = Object.keys(selectedEvents).length;
                                        const totalRequiredEvents = (productTypes && productTypes[Object.keys(productTypes)[0]].product_type_events_required) || 1;
                                        //handle primary job condition
                                        const personID = opportunity.related_objects.jobs[opportunity.main.job_id].person_id;
                                        const personObj = opportunity.related_objects.persons[personID];
                                        let errors = [];

                                        setSubmitting(false);
                                        if (count >= totalRequiredEvents && personObj.email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(personObj.email)) {
                                            this.setState({formValues: values}, () => {
                                                this.handleModal('openWon');
                                            })
                                        } else {
                                            if (count < totalRequiredEvents) {
                                                let msg = `Error: You must select at least ${totalRequiredEvents} event(s) to close this opportunity.`;
                                                errors.push(msg);
                                                this.setState({errorMsg: errors})
                                            }
                                            if (!personObj.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(personObj.email)) {
                                                let msg = `Error: The primary job of the person must have a valid email address.`;
                                                errors.push(msg);
                                                this.setState({errorMsg: errors})
                                            }
                                        }
                                    }}>

                                    {({submitForm, isSubmitting, values, setFieldValue}) => (
                                        <Form>
                                            <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                <Grid item xs={12}>
                                                    <Field
                                                        required
                                                        sx={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            maxWidth: '400px',
                                                            margin: 'auto'
                                                        }}
                                                        classes={{input: classes.noPadding}}
                                                        name="opportunity_cvr"
                                                        type="text"
                                                        label="CVR"
                                                        variant="outlined"
                                                        margin="normal"
                                                        component={TextField}
                                                        onBlur={this.handleCVR}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                    <div className='info-message'>
                                                        <p>
                                                            SE Only - CVR Number format is xxxxxxx-xxxx
                                                        </p>
                                                        <p>
                                                            Please enter -1 if client will pay himself instead company's
                                                            account
                                                        </p>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        required
                                                        name="company_name"
                                                        type="text"
                                                        label="Company Name"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "company")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Tooltip
                                                                        title={opportunity?.main?.opportunity_cvr_company ? "Company name is from opportunity CVR" : "Company name is from person's job"}>
                                                                        <IconButton>
                                                                            <Info/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_1"
                                                        type="text"
                                                        label="Address 1"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "company")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_2"
                                                        type="text"
                                                        label="Address 2"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "company")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_postal_code"
                                                        type="text"
                                                        label="Zip Code"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "company")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_city"
                                                        type="text"
                                                        label="City"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "company")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_address_country"
                                                        type="text"
                                                        label="Country"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "company")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{marginBottom: '15px'}} container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h6" align="left"
                                                                color="textPrimary" gutterBottom>
                                                        Bookkeeping info
                                                    </Typography>
                                                </Grid>
                                                <Grid container sx={{paddingRight: "0px", marginRight: "0px"}}>
                                                    <Grid item xs={11}>
                                                        <Typography component="h1" variant="h6" align="left"
                                                                    color="textPrimary"
                                                                    gutterBottom>
                                                            {opportunity ? "Company info for invoice" : ""}
                                                        </Typography>
                                                    </Grid>
                                                    {this.state.showBookkeepingSave &&
                                                        <Grid item xs={1} sx={{textAlign: "right"}}>
                                                            <Tooltip title='Save' arrow placement='top'>
                                                                <IconButton
                                                                    aria-label='Save'
                                                                    aria-haspopup='false'
                                                                    onClick={this.saveOpportunity}
                                                                    style={{
                                                                        color: "#000",
                                                                        fontSize: "17px",
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    <Save color='primary'/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    }
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_bookkeeper_email"
                                                        type="text"
                                                        label="Bookkeeper e-mail"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "bookkeeping")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_ean"
                                                        type="text"
                                                        label="EAN"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        component={TextField}
                                                        onBlur={(e) => {
                                                            let opportunityEan = values.opportunity_ean?.trim();
                                                            opportunityEan = opportunityEan?.replace(/^0+/, "");

                                                            setFieldValue('opportunity_ean', opportunityEan)
                                                            this.handleBlur(e, "bookkeeping")
                                                        }}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Field
                                                        name="opportunity_po_number"
                                                        type="text"
                                                        label="PO Number"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "bookkeeping")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid sx={{marginBottom: '15px'}} container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography component="h1" variant="h6" align="left"
                                                                color="textPrimary">
                                                        Payment Terms
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        disablePortal
                                                        size='small'
                                                        id="combo-box"
                                                        options={delayInvoiceOptions}
                                                        sx={{width: '100%'}}
                                                        onChange={(_e, value) => setFieldValue("opportunity_delayed_invoice", value.key)}
                                                        onBlur={(e) => {
                                                            let formData = {...this.state.opportunityPatchData};

                                                            formData.opportunity_delayed_invoice = e.target.value;

                                                            this.setState({"opportunityPatchData": formData});
                                                            this.displayBookkeepingSave();
                                                        }}
                                                        value={values.opportunity_delayed_invoice}
                                                        renderInput={(params) => <MaterialTextField {...params}
                                                                                                    label="Delay Invoice"/>}
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Field
                                                        name="opportunity_price"
                                                        type="text"
                                                        label="Price"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "bookkeeping")}
                                                        size="small"
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                                <Grid item xs={5} sx={{display: 'flex', alignItems: 'center'}}>
                                                    <label>
                                                        <Field type="checkbox" name="opportunity_split_payment"
                                                               disabled={!this.isOpportunityStatusOpen}
                                                               onChange={(e) => this.handleBlur(e, "bookkeeping")}
                                                        />
                                                        50% now, 50% after 2nd meeting
                                                    </label>
                                                </Grid>
                                            </Grid>

                                            <Grid sx={{marginBottom: '15px'}} container spacing={1}>
                                                <Grid item xs={11}>
                                                    <Typography component="h1" variant="h6" align="left"
                                                                color="textPrimary">
                                                        Extra Information
                                                    </Typography>

                                                </Grid>
                                                {this.state.showExtraInformationSave &&
                                                    <Grid item xs={1} sx={{textAlign: "right"}}>
                                                        <Tooltip title='Save' arrow placement='top'>
                                                            <IconButton
                                                                aria-label='Save'
                                                                aria-haspopup='false'
                                                                onClick={this.saveOpportunity}
                                                                style={{
                                                                    color: "#000",
                                                                    fontSize: "17px",
                                                                    fontWeight: "600",
                                                                }}
                                                            >
                                                                <Save color='primary'/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Typography component="h6" variant="h6" align="left"
                                                                color="textPrimary"
                                                                sx={{fontSize: '16px', margin: '10px 0 5px'}}>
                                                        Food Consideration
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        this.isOpportunityStatusOpen() ? (
                                                            <>
                                                                <div className="food-consideration-section">
                                                                    <SelectBox
                                                                        multiple={false}
                                                                        name="person_food_considerations_1"
                                                                        type="type1_food"
                                                                        label="Select Type"
                                                                        labelId="select-type"
                                                                        options={foodConsiderations ? foodConsiderations : []}
                                                                        onBlur={(e) => this.handleBlur(e, "extraInformation")}
                                                                    />
                                                                    <SelectBox
                                                                        multiple={true}
                                                                        name="person_food_considerations_2"
                                                                        type="type2_food"
                                                                        label="Select Allergy"
                                                                        labelId="select-allergy"
                                                                        onChange={this.handleAllergiesChange}
                                                                        options={foodConsiderations ? foodConsiderations : []}
                                                                    />
                                                                </div>
                                                                {
                                                                    this.formRef?.current?.values?.person_food_considerations_2?.filter((item) => item === 16).length > 0 && (
                                                                        <Field
                                                                            component="textarea"
                                                                            fullWidth
                                                                            rows="5"
                                                                            size="large"
                                                                            name="person_food_considerations_other"
                                                                            style={{
                                                                                width: '100%',
                                                                                padding: '10px',
                                                                                fontSize: '16px',
                                                                                borderColor: "#c4c4c4",
                                                                                borderRadius: '5px'
                                                                            }}
                                                                            placeholder="Please write other allergies"
                                                                            onBlur={(e) => this.handleBlur(e, "extraInformation")}
                                                                        />
                                                                    )
                                                                }
                                                            </>
                                                        ) : (
                                                            <Field
                                                                type="text"
                                                                label="Food Consideration"
                                                                name="opportunity_food_considerations"
                                                                multiline
                                                                rows="5"
                                                                variant="outlined"
                                                                margin="normal"
                                                                fullWidth
                                                                component={TextField}
                                                                disabled={true}
                                                            />
                                                        )
                                                    }

                                                    <Field
                                                        type="text"
                                                        label="Special conditions"
                                                        name="opportunity_special_conditions"
                                                        multiline
                                                        rows="4"
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        component={TextField}
                                                        onBlur={(e) => this.handleBlur(e, "extraInformation")}
                                                        disabled={!this.isOpportunityStatusOpen}
                                                    />
                                                </Grid>
                                            </Grid>

                                            {
                                                this.isOpportunityStatusOpen() && (
                                                    <>
                                                        <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                            <Grid item xs={12}>
                                                                <div>
                                                                    {
                                                                        this.state.errorMsg.map((msg, index) => {
                                                                            return (
                                                                                <p key={index} style={{
                                                                                    textAlign: 'center',
                                                                                    color: 'red',
                                                                                    margin: 0
                                                                                }}>{msg}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                            <Grid item xs={12} sx={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between'
                                                            }}>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    disabled={isSubmitting}
                                                                    onClick={() => this.handleModal('openLost')}
                                                                >
                                                                    Close as lost
                                                                </Button>
                                                                {
                                                                    opportunity && capability.length > 0 ? (
                                                                        <Button
                                                                            variant="contained"
                                                                            color="warning"
                                                                            disabled={isSubmitting}
                                                                            onClick={() => this.handleModal('openUnsuccessful')}
                                                                        >
                                                                            Unsuccessful campaign
                                                                        </Button>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                                <LoadingButton
                                                                    variant="contained"
                                                                    color="success"
                                                                    disabled={isSubmitting}
                                                                    onClick={submitForm}
                                                                    loading={postClosedWon_loading}
                                                                >
                                                                    Close as won
                                                                </LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container sx={{marginBottom: '15px'}} spacing={1}>
                                                            <Grid item xs={12}>
                                                                <Typography component="h1" variant="h6" align="center"
                                                                            color="textPrimary">
                                                                    Transfer opportunity to another user
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}
                                                                  sx={{display: 'flex', justifyContent: 'center'}}>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="combo-box-demo"
                                                                    options={users}
                                                                    sx={{width: 300}}
                                                                    onChange={this.handleTransferOpportunity}
                                                                    renderInput={(params) =>
                                                                        <MaterialTextField {...params}
                                                                                           label="Select a user"/>}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )
                                            }

                                            <LostModalV2
                                                open={this.state.openLost}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formDataValues={this.formRef.current === null ? null : this.formRef.current.values}
                                            />

                                            <UnsuccessfulModal
                                                open={this.state.openUnsuccessful}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formDataValues={this.formRef.current === null ? null : this.formRef.current.values}
                                            />

                                            <WonModal
                                                open={this.state.openWon}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formData={this.state.formValues}
                                            />

                                            <CvrModal
                                                open={this.state.openCvr}
                                                onClose={this.handleClose}
                                                opportunity={opportunity}
                                                formData={this.formRef.current === null ? null : this.formRef.current.values}
                                                cvrData={this.state.cvrData}
                                                formReference={this.formRef}
                                            />

                                            <TransferModal
                                                open={this.state.openTransfer}
                                                onClose={this.handleClose}
                                                pipelineID={this.state.pipelineID}
                                                emptySalesRep={() => this.setState({salesRep: ""})}
                                                formDataValues={this.formRef.current === null ? null : this.formRef.current.values}
                                            />

                                            {financeEnabled && productCountry?.country_name === COUNTRY_DENMARK &&
                                                <SwitchToInvoicesConfirmationModal
                                                    open={this.state.openSwitchToInvoicesConfirmation}
                                                    onClose={this.handleClose}
                                                />
                                            }

                                        </Form>
                                    )}
                                </Formik>
                            ) : (
                                <></>
                            )
                        }
                    </Card>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    errorMessage: opportunitySelectors.errorMessage(state),
    opportunity: opportunitySelectors.opportunity(state),
    partialOpportunityData: opportunitySelectors.partialOpportunityData(state),
    salesReps: opportunitySelectors.salesReps(state),
    cvr: opportunitySelectors.cvr(state),
    pipelineId: opportunitySelectors.pipelineId(state),
    stages: opportunitySelectors.stages(state),
    pipelines: dashboardSelectors.pipelines(state),
    user: accountSelectors.user(state),
    foodConsiderations: contactsSelectors.foodConsiderations(state),
    postClosedWon_loading: opportunitySelectors.postClosedWonLoading(state),
    closedWon: opportunitySelectors.closedWon(state),
    opportunity_loading: opportunitySelectors.opportunity_loading(state),
    oppSaveInfoLoader: opportunitySelectors.oppSaveInfoLoader(state),
    financeEnabled: adminSelectors.financeEnabled(state),
    patchOpportunityDataLoading: opportunitySelectors.patchOpportunityDataLoading(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        patchOpportunityData: opportunityActions.patchOpportunityData,
        postOpportunityData: opportunityActions.postOpportunityData,
        searchCvr: opportunityActions.searchCvr,
        getSalesReps: opportunityActions.getSalesReps,
        getPipelineId: opportunityActions.getPipelineId,
        getStages: opportunityActions.getStages,
        getFoodConsiderations: contactsActions.getFoodConsiderations
    },
    dispatch);

function WithNavigate(props) {
    let params = useParams();
    return <Deal {...props} params={params}/>
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(WithNavigate));
