import * as React from "react";
import {useCallback, useState} from "react";
import GridElement from "../../Common/GidElement";
import {makeAccessor, makeHeader, prepareFilterQuery, prepareSortQuery} from "../../Utils/gridUtils";
import {Add, Delete, Edit} from "@mui/icons-material";
import Button from "@mui/material/Button";
import ManageBookkeeperModal from "./ManageBookkeeperModal";
import {IconButton, Tooltip} from "@mui/material";
import Swal from "sweetalert2";
import {deleteBookkeeper, getBookkeepers} from "../../../API/bookkeeper";
import {toast} from "react-toastify";

const BookkeeperGrid = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [rowsCount, setRowsCount] = useState(0);
    const [filters, setFilters] = useState([]);
    const [controlledPageIndex, setControlledPageIndex] = useState(0);
    const [refreshGridData, setRefreshGridData] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openManageBookkeeperModal, setOpenManageBookkeeperModal] = useState(false);
    const [bookkeeper, setBookkeeper] = useState(null);
    const [cols, _setCols] = useState([{
        column: "cvr",
        accessor: makeAccessor("cvr"),
        minWidth: 150,
        Header: "CVR",
        filter: "fuzzyText",
    },
        {
            column: "company_name",
            accessor: makeAccessor("company_name"),
            minWidth: 150,
            Header: makeHeader("company_name"),
            filter: "fuzzyText",
        },
        {
            column: "ean",
            accessor: makeAccessor("ean"),
            minWidth: 200,
            Header: "EAN",
            filter: "fuzzyText",
        },
        {
            column: "bookkeeper_email",
            accessor: makeAccessor("bookkeeper_email"),
            minWidth: 200,
            Header: makeHeader("bookkeeper_email"),
            filter: "fuzzyText",
        },
        {
            column: "po_number",
            accessor: makeAccessor("po_number"),
            minWidth: 150,
            Header: "PO Number",
            filter: "fuzzyText",
        },
        {
            Header: "Action",
            filter: "fuzzyText",
            Cell: ({row}) => (
                <div>
                    <Tooltip title='Edit' arrow placement='top'>
                        <IconButton
                            aria-label='edit bookkeeper'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={() => {
                                setOpenManageBookkeeperModal(true);
                                setBookkeeper(row.original);
                            }}
                            style={{
                                color: "#000",
                                fontSize: "17px",
                                fontWeight: "600",
                            }}
                        >
                            <Edit color='primary'/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete Bookkeeper' arrow placement='top'>
                        <IconButton
                            aria-label='delete bookkeeper'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={() => handleDeleteBookkeeperClick(row.original)}
                            style={{
                                color: "#FF0000",
                                fontSize: "17px",
                                fontWeight: "600",
                            }}
                            disabled={isDeleting}
                        ><Delete/></IconButton>
                    </Tooltip>
                </div>
            ),
        }
    ]);

    const resetFilters = useCallback(() => setFilters([]), [setFilters]);
    const fetchData = useCallback(({pageSize, pageIndex, sortBy, filters, columns}) => {
        let _columns = columns.map((item) => item.column);
        _columns = _columns.filter((item) => item !== undefined);

        let query = prepareFilterQuery(filters, columns);
        let sort = prepareSortQuery(sortBy, columns);

        setLoading(true);

        getBookkeepers(pageSize, _columns, pageIndex, query, sort).then((res) => {
            setData(res.data.main);
            setPageCount(res.data.offset.highest_offset);
            setRowsCount(res.data.offset.rows_count);
            setLoading(false);
        }).catch((error) => {
            toast.error(error.error);
            setLoading(false);
        })

        setRefreshGridData(false); //if grid fetches latest data then set refreshGridData to false
    }, []);

    const handleManageBookkeeperClose = (refreshGrid = false) => {
        setOpenManageBookkeeperModal(false);
        setRefreshGridData(refreshGrid);
        setBookkeeper(null);
    };

    const handleDeleteBookkeeperClick = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsDeleting(true);
                deleteBookkeeper(data.bookkeeper_id).then((res) => {
                    toast.success(res.message);
                    setRefreshGridData(true);
                    setIsDeleting(false);
                }).catch((error) => {
                    toast.error(error);
                    setIsDeleting(false);
                });
            }
        });
    }

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h2 className='page-title'>Bookkeepers</h2>
                <div>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{marginRight: '5px'}}
                        onClick={() => setOpenManageBookkeeperModal(true)}>
                        <Add aria-hidden='false' color='white'/>
                        Create Bookkeeper
                    </Button>
                </div>
            </div>

            <GridElement
                columns={cols}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
                controlledPageIndex={controlledPageIndex}
                setControlledPageIndex={setControlledPageIndex}
                filters={filters}
                setFilters={setFilters}
                resetFilters={resetFilters}
                rowsCount={rowsCount}
                handleRowClick={() => {
                }}
                initialSortBy='created_at'
                sortOrder='desc'
                hideSelection={true}
                refreshGridData={refreshGridData}
            />
            <ManageBookkeeperModal
                open={openManageBookkeeperModal}
                handleClose={handleManageBookkeeperClose}
                data={bookkeeper}/>
        </>
    )
}

export default BookkeeperGrid;